<template>
  <div class="car-confirmation">
    <Header :isHeader="true" :back="true" :step="currentStepIndex" />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar />
      </template>
      <Title titleStyle="title" :text="translateString('identifyVehicle')" />
      <CarInfo :justMobile="true" />
      <List :data="carListedInfo" />
      <p v-if="carCombo.comboSelectedByUser" class="sm-text-italic">
        {{ carCombo.comboLabel }}
      </p>
      <br />
      <div class="row flex-wrap align-btn-mobile align-container-btn mb-2">
        <Button
          class="btn-tertiary elm-mobile"
          :label="translateString('changePlate')"
          @action="changePlate()"
        />
        <Button
          class="btn-tertiary elm-mobile b-1"
          :label="translateString('changeVehicle')"
          @action="changeCar()"
        />
      </div>
      <br />
      <template v-slot:footer>
        <Footer>
          <Button
            id="getDealers"
            class="btn-primary align-btn-tertiary"
            :label="translateString('continue')"
            @action="next('getDealers')"
          />
          <Button
            class="btn-secondary elm-desktop"
            :label="translateString('changePlate')"
            @action="changePlate()"
          />
          <Button
            class="btn-secondary elm-desktop"
            :label="translateString('changeVehicle')"
            @action="changeCar()"
          />
        </Footer>
      </template>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import List from "@/components/List/List.vue";
import Modal from "@/components/Modal/Modal.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Title from "@/components/Title/Title.vue";
import CarInfo from "@/components/UpdatedResume/CarInfo.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";
import { mapGetters } from "vuex";

import { formatNumber, translateString } from "@/functions/functions.js";

export default {
  name: "CarConfirmation",
  components: {
    Button,
    CarInfo,
    Footer,
    Header,
    List,
    Modal,
    Sidebar,
    Title,
    Wrapper,
  },
  data() {
    return {
      carName: this.$store.state.carName,
      carKms: formatNumber(this.$store.state.carKms, "."),
      carData: this.$store.state.carByPlate,
      carCombo: this.$store.state.carComboSelected,
      carListedInfo: [],
      carBrand: this.$store.state.carByPlate.brand
        ? this.$store.state.carByPlate.brand
        : this.$store.state.carNF.brand,
      selectedCarKtype: this.$store.getters["getSelectedCarKtype"],
      selectedCarKtypeInfo: this.$store.getters["getSelectedCarKtypeInfo"],
    };
  },
  computed: {
    MyselectedCarKtype() {
      return this.$store.getters.getSelectedCarKtype;
    },
    MyselectedCarKtypeInfo() {
      return this.$store.getters.getSelectedCarKtypeInfo;
    },
    finalError() {
      return this.$store.state.finalError;
    },
    dealersReady() {
      return this.$store.state.dealersByBrandReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    ...mapGetters(["getCurrentStepIndex"]),

    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
  },
  created() {
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    if (this.$store.state.carPlate == "") {
      this.$router.replace({ name: "Homepage" });
    }
    this.carListedInfo = [
      {
        label: translateString("plateDate"),
        value: this.carPlate,
        condition: this.carPlate ? true : false,
      },
      {
        label: translateString("model"),
        value:
          this.carData.brand && this.carData.model
            ? this.carData.brand + " " + this.carData.model
            : this.$store.state.carNF.brand +
              " " +
              this.$store.state.carNF.model,
        condition: true,
      },


      {
        label: translateString("version"),
        value: this.carData.typeModel
          ? this.carData.typeModel
          : this.$store.state.carNF.version,
        condition:
          this.carData.typeModel || this.$store.state.carNF.version
            ? true
            : false,
      },
      {
        label: translateString("gearType"),
        value: this.carData.gearbox,
        condition:
          this.carData.gearbox != "" &&
          this.carData.gearbox != "Nd" &&
          this.carData.gearbox
            ? true
            : false,
      },
      {
        label: translateString("brandModel"),
        value: this.carData.chassi
          ? this.carData.chassi
          : this.$store.state.carNF.body,
        condition:
          this.carData.chassi || this.$store.state.carNF.body ? true : false,
      },
      {
        label: translateString("doorsNumber"),
        value: this.carData.nDoors,
        condition: this.carData.nDoors ? true : false,
      },
      {
        label: translateString("fuel"),
        value: this.carData.fuel
          ? this.carData.fuel
          : this.$store.state.carNF.fuel,
        condition:
          this.carData.fuel || this.$store.state.carNF.fuel ? true : false,
      },
      {
        label: translateString("cc"),
        value: this.carData.cc + " cc",
        condition: this.carData.cc ? true : false,
      },
      {
        label: translateString("horsePower"),
        value:
          typeof this.MyselectedCarKtypeInfo === "string" ||
          typeof this.MyselectedCarKtypeInfo === "number"
            ? this.MyselectedCarKtypeInfo
            : this.carData.ps,
        condition:
          typeof this.MyselectedCarKtypeInfo === "string" ||
          typeof this.MyselectedCarKtypeInfo === "number"
            ? true
            : (this.carData.ps === false || this.carData.ps === undefined ? false : true),
      },
      {
        label: translateString("kms"),
        value: this.carKms + " kms",
        condition: this.carKms ? true : false,
      },
    ];
  },
  methods: {
    translateString,
    next(id) {
      if (this.dealersReady) {
        this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
        this.$router.push({ name: "DealerSelection" });
        /* this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1); */
      } else {
        this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
        this.$store.dispatch("getDealerByBrand", this.carBrand);
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: id,
        });
      }
    },
    changeCar() {
      this.$router.push({ name: "PlateNotFound" });
    },
    changePlate() {
      this.$router.push({ name: "Homepage" });
    },
  },
  watch: {
    dealersReady() {
      if (this.dealersReady == true) {
        this.$router.push({ name: "DealerSelection" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>
